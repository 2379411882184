:root {
    --theme-blue: #007aa6;
    --theme-teal-logo: #00b3b0;
    --theme-teal-light: hsl(190, 50%, 50%);
    --theme-teal: hsl(190, 95%, 30%);
    --theme-teal-dark: hsl(190, 100%, 20%);
    --theme-green-logo: #00b747;
    --theme-orange: #ff9b00;
    --theme-orange-dark: #ae6c00;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
