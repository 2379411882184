@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css");

.modal-content {


}

html, body {
  max-width: 100%; // Ensure content does not exceed viewport width
  box-sizing: border-box;
}

* {
  box-sizing: inherit; // Ensures consistent sizing model for all elements
}

// this is a cheat to remove the default 'Resend Code' button in Authenticator.
// Not ideal but at the moment there is no proper handle to remove it without this.
//todo: check delivery of the codes
#authModal form[data-amplify-form][data-amplify-authenticator-confirmsignup] fieldset .amplify-flex .amplify-field-group__control[type="button"] {
  display: none;
}

.auth-link {
  color: var(--theme-teal-dark);
  text-decoration: underline;
  font-size: 110%;
  cursor: pointer;
}

.auth-link:hover {
  text-decoration: underline;
  color: var(--theme-teal-dark);
}

.auth-link, .logInButton {
  display: inline-block;
  vertical-align: middle;
  padding: 5px 10px;
  margin: 0;
  font-size: 16px; /* Make sure sizes are aligned */
  text-align: center;
  line-height: 1.5;
}

.blurry-text-1 {
  color: transparent;
  text-shadow: 0 0 2px rgba(0, 0, 0, 0.6);
}

.blurry-text-2 {
  color: transparent;
  text-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
}

.blurry-text-3 {
  color: transparent;
  text-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
}

.blurry-text-max {
  color: transparent;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.blurry-text-max-red {
  color: transparent !important;
  text-shadow: 0 0 5px #CC000030;
}

.blurry-surprise {
  color: transparent !important;
  opacity: 50%;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.mostCorrWrapper {
  position: relative;
}

.signUpFromResults {
  position: absolute;
  top: 0;
  left: 2rem;
  background-color: white;
  border: solid 1px var(--theme-orange);
  color: darkorange;
  border-radius: 3px;
}

.signUpFromResults:hover, .signUpFromResults:active {
  background-color: var(--theme-orange);
  border: solid 1px var(--theme-orange);
  color: white;
}

.main_top {
  .logInButton, .contactButton {
    font-size: 110%;
    color: var(--theme-teal-dark);
    //font-weight: bold;
  }

  .signUpButton {
    border: 2px var(--theme-teal) solid;
    background-color: var(--theme-teal);
    color: white;
    font-size: 108%;
    font-weight: bold;
    border-radius: 3px;
    padding: 0.5rem 1.2rem;
  }

  .signUpButton:hover, .signUpButton:active {
    color: white;
    background-color: var(--theme-teal-dark);
    border-color: var(--theme-teal-dark);
  }
}

.debugNotice {
  position: absolute;
  right: 1rem;
  top: 1rem;
}

.debugStudy {
  white-space: nowrap;

}

.resultsFound h2 {
  padding-bottom: 0 !important;
}

.mainLinkDebug {
  text-decoration: none !important;
  font-weight: normal !important;
  font-size: 80%;
  font-style: oblique;
}

.graphWrapper {
  width: 100%;
  height: 40vh;
  min-height: 300px;
  max-height: 400px;
}

input {
  padding: 1rem;
}

.scoreSpan, .actualCorrCell {
  align-items: start;
  text-align: center;
  padding: 0.7rem 0;
  display: flex;
  justify-content: space-around;
}

//.surprisingToggleWrapper {
//  display: flex;
//  justify-content: flex-start;
//  font-size: 1rem;
//  float: right;
//  position: relative;
//  top: -1.6rem;
//  padding-top: 1rem;
//}
.surprisingToggleWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: end;
  font-size: 1rem;
}

.form-check input {
  padding: initial;
}

.form-check label {
  //color: #006f6c;
}

.surprisingCell {
  font-size: 1.1rem;
  color: #006f6c;
  vertical-align: top;
  padding: 0.7rem 1rem 0.7rem 0;
  width: 2.5rem;
}

.actualCorrCell {
  text-align: end;
}

.resultQText {

  padding: 0.7rem 2rem;
}

.questionResult {
  //max-width: 30rem;
  //padding-bottom: 2rem;
}

.questionResult:hover {
  background: lightgoldenrodyellow;
  cursor: pointer;
}

.backNavigation {
  cursor: pointer;
  font-size: 97%;
  background: none;
  border: none;
  color: var(--bs-body-color);

}

.backNavigation:disabled {
  cursor: not-allowed;
  opacity: 0.6; /* Slightly faded look */
}

button.amplify-tabs__item:first-child {
  //border-right: 1px solid rgb(33, 37, 41);
}

button.amplify-tabs__item[aria-selected="true"] {
  pointer-events: none;
  opacity: 0.8;
  cursor: not-allowed;
  text-decoration: none;
  //border-bottom: none;
  //color: rgb(33, 37, 41);

}

button.amplify-tabs__item[aria-selected="false"] {
  pointer-events: auto;
  cursor: pointer;
  text-decoration: underline;
  //color: #006f6c;
  //border-bottom: 1px solid rgb(33, 37, 41);
  border-top: none;
}


.heightSmall {
  height: 1rem;
  display: block;
}


.searchResultsDiv {
  margin-top: 2rem;
}

.hoverPointer:hover {
  cursor: pointer;
}

.red {
  color: #CC0000;
}

.green {
  color: green;
}

h2 {

}

.tab-content {
  //padding-right: 0;
  //padding-left: 0;
}

.top-row-block-wrapper {
  position: relative; /* Ensure the container for absolute positioning */
  width: 100%;
  height: 100%;
}

.top-row-block {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: 100%;
}

/* Styles for screens smaller than 991px */
@media (max-width: 991px) {
  .top-row-block {
    flex-direction: column;
  }

  .small-input-block,
  .regular-input-block {
    width: 100%;
  }

  .share-block-container {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.small-input-block,
.regular-input-block {
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-width: 0;
}

.share-block-container {
  flex-shrink: 0;
  display: inline-flex;
  align-items: flex-start;
}

.regular-input-block .inputWrapper {
  padding-top: 4rem;
  transition: all 0.3s ease;
  font-size: 100%;
  margin-right: 0;
}

.small-input-block .inputWrapper {
  padding-top: 0;
  transition: all 0.3s ease;
  font-size: 90%;
  margin-right: 5rem;
}

.inputWrapper button {
  font-size: 100%;
}

.results-block {
  position: relative; // Ensure it's relative to the Tab.Content parent
  margin-top: 20px; // Adjust the spacing between .top-row-block and .results-block
}

.same-height-flex-container {
  display: flex;
  align-items: stretch;
}

.same-height-flex-container > * {
  flex: 1; /* Each child takes up an equal amount of space */
}

.inputGroupLabelTwoLine,
.inputGroupLabelOneLine {
  line-height: 1.0;
  border: #E0E0E0 1px solid;
  background: #E0E0E0;
  text-align: center;
  display: flex;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
}

.inputGroupLabelTwoLine {
  height: 3rem;
  border-radius: 5px 0 0 5px;
}

.inputGroupLabelOneLine {
  height: 2.5rem;
  border-radius: 5px 0 0 5px;
}

@media (min-width: 992px) {
  .share-text {
    display: inline;
  }
  .shareBtn i {
    font-size: 100%;
  }
}

/* Define styles for smaller screens */
@media (max-width: 991px) {
  .share-text {
    display: none;
  }

  .shareBtn i {
    font-size: 120%;
  }


}

//To ensure that no more than two rows are created and to have at least three buttons move to the second row when necessary
#question-data-tabnav {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr)); // Automatically fit columns with a minimum size
  gap: 10px;
  justify-content: center; /* Center content horizontally if there are fewer items than columns */
  grid-auto-flow: row dense; /* Fill rows in a dense packing mode */
  @media (max-width: 991px) {
    grid-template-columns: repeat(4, 1fr); // Use four columns for smaller screens
    justify-content: center;
  }
  @media (max-width: 576px) {
    display: flex;
    justify-content: center;
    gap: 0;
  }
}

#question-data-tabnav li {
  list-style: none;
  display: flex; /* Add flex display */
  justify-content: center; /* Center the button horizontally within li */
}

#question-data-tabnav li button {
  line-height: 1.3;
}

@media (max-width: 991px) {
  #question-data-tabnav li {
    &:nth-child(1) {
      grid-column: 1;
      grid-row: 1;
    }

    &:nth-child(2) {
      grid-column: 2;
      grid-row: 1;
    }

    &:nth-child(3) {
      grid-column: 3;
      grid-row: 1;
    }

    &:nth-child(4) {
      grid-column: 4;
      grid-row: 1;
    }

    &:nth-child(5) {
      grid-column: 1;
      grid-row: 2;
      margin-left: auto;
    }

    &:nth-child(6) {
      grid-column: 2;
      grid-row: 2;
    }

    &:nth-child(7) {
      grid-column: 3;
      grid-row: 2;
      margin-right: auto;
    }
  }
}

.very_small_font {
  font-size: 80%;
  white-space: nowrap;
  display: block;

}

#inputNotification {
  //position: absolute;
  //top: -0.3rem;
  //left: 0.3rem;
  display: block;
  height: 1.5rem;
}

.hide {
  display: none;
}

.inputWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 0; // optional, sets a gap between the children
}

input {
  display: block;
}

input:invalid {

}

.actualCorrLoading {
  position: absolute;
  left: 0;
  top: 0;
}

.suggestionsBox {
  max-height: 20vh;
  display: block;
  overflow-y: scroll;
  color: #3e5058;
  border: lightgray 1px solid;
  border-top: none;
  margin: .2rem;
}


.suggestionsWrapper {
  padding-bottom: 0 !important;
  height: 100%;
}

.disabled.suggestionRow:hover {
  cursor: not-allowed;
}

.disabled.suggestionsWrapper {

}

input {
  height: 2.5rem;
  border: lightgray 1px solid;
  border-radius: 2px;
}

.border-0 {
  border: none !important;
}

.searchPage button {
  border: #007aa5 2px solid;
  background: #007aa5;
  color: #FFFFFF;
  padding-right: 2rem;
  padding-left: 2rem;
  margin-left: 0 !important;
  border-radius: 0 5px 5px 0;
  height: 2.5rem;
  line-height: 1.0;
}


.superscript {
  position: relative;
  top: -0.5em;
  font-size: 80%;
}

.r2div {
  color: #006f6c;
  padding-right: 2rem;
}

.questionTab input {
  width: 100%;
  height: 2.5rem;
  border-radius: 0;
}

.searchPage input {
  width: 100%;
  border-radius: 5px 0 0 5px;
}

.questionTab button {
  height: 2.5rem;
  //line-height: 1.0;
  border-radius: 0 5px 5px 0;
  line-height: 1rem;
  padding: 0 0.5rem;
}

.examplesDiv {
  color: #424242;
  width: 80vw;
  font-size: 92%;
  max-width: 50rem;
}

.examplesTitle {
  padding: .3rem;
  font-size: 90%;
}

.exampleSpan {
  display: inline-flex;
  padding: .2rem 1rem;
  margin: 0.15rem 0.3rem 0.15rem 0;
  //white-space: pre;
  border: 1px solid #efefef;
  border-radius: 10px;
}

.exampleSpan.locked {
  color: gray;
}

.randomizeExamplesBtn {
  padding: .2rem 1rem 0;
  text-decoration: underline;
  color: #0085ba;
  display: inline-flex;
  margin: 0.3rem 0.3rem 0 0;
  //white-space: pre;
}

.chart h4 {
  text-align: center;
  max-width: 500px;
}

.chart {
  min-width: 500px;
  max-width: 700px;
  width: 90vw;
}

.graphWrapper {
  position: relative;
}

.msg {
  position: absolute;
  right: 30px;
  top: -30px;
}

.statsTableWrapper {
  padding-top: 2rem;
  padding-left: 3rem;

}

.statsChartHeading {
  margin-left: 3rem;
}

.statsControls {
  margin-top: 20px;
  padding-left: 3rem;
  margin-right: 10px;
}

.graphWrapper .recharts-legend-item:last-child {
  margin-right: 0 !important;
}

.predictCorrRow:hover {
  background: #efefef50;
}

.actualCorrCell {
  display: inline-flex;
  align-content: flex-start;
  text-align: start;
  max-width: 20vw;
  min-width: 3rem;
  //padding-right: 0.3rem;
  padding-left: 0.5rem;
}

.table-heading {
  line-height: 1.2;
  padding-bottom: 1rem;


}

.btnRemoveRegItem {
  color: gray;
}

.QuestionSearchContainer h2 {
  max-width: 95%;
}

.h2Icon {
  height: 100%;
  display: block;
}

.nav.nav-tabs .nav-item {
  display: flex;
  align-content: center;
}

@media screen and (max-width: 400px) {
  .nav-item {
    width: 95vw !important;
  }
}

@media screen and (max-width: 500px) {
  .nav-item {
    min-width: 45vw !important;

  }
}

@media screen and (min-width: 501px) {
  .nav-item {
    max-width: 25vw !important;
  }
}

@media screen and (min-width: 1024px) {
  .nav-item {
    max-width: 25vw !important;
  }
}


/**
 * ==============================================
 * Dot Flashing
 * ==============================================
 */
.stage {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 0 -5%;
  overflow: hidden;
  min-width: 3rem;
  height: 100%;
  min-height: 1.5rem;

}

.dot-flashing {
  position: relative;
  width: 3px;
  height: 3px;
  border-radius: 2px;
  background-color: #004e6e90;
  color: #004e6e90;
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}

.dot-flashing::before, .dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -7px;
  width: 3px;
  height: 3px;
  border-radius: 2px;
  background-color: #004e6e80;
  color: #004e6e80;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 7px;
  width: 3px;
  height: 3px;
  border-radius: 2px;
  background-color: #004e6e90;
  color: #004e6e90;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 1s;
}


@keyframes dot-flashing {
  0% {
    background-color: #004e6e90;
  }
  50%, 100% {
    background-color: #004e6e30;
  }
}

.form-check-input:focus {
  box-shadow: none !important;
}

.modal-header {
  border-bottom: none !important;
}

.modal-footer {
  border-top: none !important;
  max-width: 35rem;
  margin: 0 auto 1rem;
}


.cyContainer {
  position: relative;
  width: 100%;
  height: 800px;
  max-height: 70vh;

}

.social, .social a, .social svg circle {
  color: var(--theme-teal);
}

.social a:hover, .social a:active {
  cursor: pointer;
}

.modal-30w .modal-content {
  min-height: 30vh;
}

.btn-warning {
  background-color: #ffc10790 !important;
  border-color: #ffc10790 !important;
}

.social button {
  width: 80%;
}

.react-share__ShareButton .share-button {
  width: 100% !important;

}

.shareBtn, .helpBtn {
  background-color: var(--theme-teal-logo);
  border-color: var(--theme-teal-logo);
  color: white;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  padding: 0 5px;
}

.helpBtn {
  margin-left: 5px;
  margin-right: 5px;
}

.helpBtn i, .shareBtn i {
  font-size: 120%;

}

.custom-offcanvas-width {
  min-width: 500px;
}

.contactBtn {
  border-radius: 3px;
  padding: calc(0.5rem + 1px) 1.2rem;
  font-size: 108%;
  margin-left: 0.5rem;
}

.form-check-input {
}

.form-switch label {
  padding-left: 5px;
}

.small {
  font-size: 0.79em;
}

.amplify-button.amplify-field-group__control.amplify-button--fullwidth {
  margin-top: 1rem !important;
}

.tab-content {
  //padding: 0!important;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-fluid-large {
  max-width: 70px;
  height: auto;
}

@media screen and (min-width: 768px) {
  .img-fluid-large {
    width: 80px;
  }
}

.wrapper {
  min-height: 100vh;
}

.studyCorrValue {
  border-radius: 5px;
  //padding-bottom: 3px;
  //line-height: 1.8;
}

.studyCorrValue:hover {
  cursor: pointer;
  background-color: #adb5bd90;

}

.display-6 {
  font-size: 2rem;
}

.factorsHeading {
  width: 100%;
  padding-left: 5rem;
  display: flex;
  justify-content: space-between;
  padding-top: 2rem;
}

.factorsBlock {
  position: relative;
  width: 100%;
  max-width: 950px;
  padding-top: 1rem;
  padding-bottom: 2rem;
}

.tab-content {
  margin-bottom: 3rem;
}

.factorsWarning {
  max-width: 22rem;
  line-height: 1.2;
}

.connectionsAlertStage {
  padding-top: 1rem;
  color: var(--bs-green);
}

@media screen and (min-width: 1024px) {
  .factorsHeading {
    padding-bottom: 0;
  }

  .factorsWarning {
    max-width: initial;
  }
}

.break-url {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  white-space: normal;
}

#question-data-tabnav {
  margin-top: 2rem;
}

.studyInfoOffcanvas {
  overflow: visible !important;
  max-height: unset !important;
  height: auto !important;
  width: 100vw;
  max-width: 100%;
  overflow-x: hidden;
  padding: 1rem;
}

.studyInfoOffcanvas .offcanvas-body {
  overflow: visible !important;
  display: block !important;
  padding: 1rem;
  overflow-x: hidden;
}


.offcanvas .offcanvas-bottom {
  height: auto;
}

.relatedArticle {
  text-decoration: none;
  cursor: pointer;
  color: var(--bs-body-color);
}

.noWrap {
  white-space: nowrap;
}

.corrDetails {
  width: 100%;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
  box-sizing: border-box;
  border-bottom: 1px solid #adb5bd90;
  position: relative !important;
  overflow: visible !important;
  padding-bottom: 1rem;
  z-index: 1050;
  display: block;
  margin: 0 auto;
}

.corrDetails a,
.corrDetails span {
  word-break: break-word;
  overflow-wrap: break-word;
  white-space: normal;
}

.sourceDetails {
  padding-top: 1rem;
}

@media (max-width: 576px) {
  .studyInfoOffcanvas {
    padding: 0.5rem;
  }

  .corrDetails, .sourceDetails {
  }

  .studyInfoOffcanvas .offcanvas-body {
    padding: 0.5rem;
  }
}

.custom-offcanvas-width {
  min-width: 500px; // Default for larger screens

  @media (max-width: 576px) {
    min-width: 90vw; // Make it nearly full-width on small screens
    padding: 0.5rem; // Adjust padding for a better appearance

  }
  @media (max-width: 576px) {
    border-left: none !important;
  }
}

.copyCell {
  position: absolute;
  top: 0.7rem;
}

.auth-panel button {
  white-space: nowrap;
}

.auth-panel {
  display: flex;
  justify-content: flex-start; // Default for larger screens
  align-items: start;
  @media (max-width: 576px) {
    justify-content: center; // Center buttons horizontally on small screens
    flex-wrap: wrap; // Allow buttons to wrap if necessary
  }
}


.statsWrapper {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 1rem;
}


@media (max-width: 576px) {
  .row {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

}

#showPredictionToggle.form-check-input:checked {
  background-color: #007aa5 !important;
  border-color: #007aa5 !important;
}

.aboutPageWrapper {
  margin-bottom: 5rem;

  h1 {
    margin-top: 5rem;
    margin-bottom: 2rem;
  }

  ul.lead {
    margin: 1rem;
  }

  ul li::marker {
    color: rgb(33, 37, 41, 0.9);
  }

  li {
    margin-top: .3rem;
  }

  h2 {
    margin-top: 4rem;
    margin-bottom: 2rem;
  }

  h3 {
    margin-top: 3rem;
    margin-bottom: 2rem;
  }

  .statementHighlight {
    display: inline-block;
    font-weight: bolder;
  }

  .resultHighlight {
    display: inline-block;
    margin: 0 0.3rem;
    font-weight: bolder;
  }
}

.aboutInflation {
  max-width: 900px;
}